import * as React from "react"
import { graphql, navigate } from "gatsby"
import {
  InstantSearch,
  Pagination,
  Hits,
  Configure,
  connectStateResults,
  connectSearchBox
} from "react-instantsearch-dom"
import styled from "styled-components"
import {
  PreStyledComponents,
  Link,
  Variables
} from "@life-without-barriers/react-components"
import { sendEvent } from "@life-without-barriers/utilities"
import { Site, TopicHit, Location } from "@life-without-barriers/gatsby-common"
import queryString from "query-string"

import SearchBox from "../../../../components/carer-guide/SearchBox"
import trimHtml from "../../../../lib/trimMarkdownHtml"
import ReadMore from "../../../../components/carer-guide/ReadMore"
import Layout from "../../../../components/carer-guide/Layout"
import { StyledPagination } from "../../../../components/carer-guide/Search/StyledPagination"
import getPageResultCount from "../../../../components/carer-guide/Search/getPageResultCount"
import NoSearchResults from "../../../../components/carer-guide/Search/NoSearchResults"
import { queryFromLocation, toSearchQuery } from "../../../../lib/searchHelpers"
import WrappedHelmet from "../../../../components/carer-guide/WrappedHelmet"

const { Container } = PreStyledComponents
const { youthPinkXXXLight } = Variables

interface Hit {
  hit: TopicHit
}

interface SearchPageProps {
  location: Location
  data: {
    site: Site
  }
}

const StyledResults = styled.div`
  em {
    background: ${youthPinkXXXLight};
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-top: 2rem;
  }

  li:last-child {
    margin-top: none;
  }
`

const MinHeight400Desktop = styled.div`
  @media screen and (min-width: 60em) {
    min-height: 400px;
  }
`

const getSearchQuery = (pageQuery: string) => {
  const decoded = queryString.parse(pageQuery)
  return decoded && decoded.query ? decoded.query : pageQuery
}

const ConnectedSearchBox = connectSearchBox(
  ({
    refine,
    currentRefinement
  }: {
    refine: (query: string | (string | null)[]) => void
    currentRefinement: string
  }) => (
    <SearchBox
      id="search"
      className="w-100 w-70-l center"
      initialQuery={currentRefinement}
      onSearch={(queryValue: string) => {
        void navigate(
          `/foster-care/support/carer-guide/search/?${toSearchQuery(
            queryValue
          )}`
        )
        refine(getSearchQuery(queryValue))
        sendEvent("Search page", "Click", "Search button")
      }}
    />
  )
)

const SearchResult = ({ hit }: Hit) => {
  return (
    <Link
      to={`/foster-care/support/carer-guide/topic/${hit.slug}`}
      title={hit.title}
      className="no-underline normal readMoreParentHover"
    >
      <span className="ts-display-4 fw8 black">{hit.title}</span>
      <div className="copy mt2 black">
        {trimHtml(hit.content.childMarkdownRemark.html)}
      </div>
      <ReadMore />
    </Link>
  )
}
interface SearchResultsProps {
  searchResults: {
    query: string
    nbHits: number
    page: number
    hitsPerPage: number
    nbPages: number
  }
}

const SearchResultsInput: React.FC<SearchResultsProps> = ({
  searchResults
}) => {
  const hasQuery = !!searchResults && searchResults.query !== ""
  const hasResults = !!searchResults && searchResults.nbHits !== 0
  const hidePagination = !hasResults || searchResults.nbHits < 11
  const pageResultCount = getPageResultCount(searchResults)

  return hasQuery ? (
    <div className="w-100 w-70-l center t-search-results">
      <MinHeight400Desktop
        hidden={!hasResults}
        className="pt3 pb3 pt5-ns ph3 ph4-m ph0-l"
      >
        <div className="lh-title">
          Showing{" "}
          <span className="fw8">
            {pageResultCount} of {searchResults.nbHits} search results
          </span>{" "}
          for &quot;<em>{searchResults.query}</em>&quot;
        </div>

        <StyledResults hidden={!hasResults}>
          <Hits hitComponent={SearchResult} />
        </StyledResults>
      </MinHeight400Desktop>

      <StyledPagination className="mt5 br2" hidden={hidePagination}>
        <Pagination className="pv2" showFirst={false} padding={2} />
      </StyledPagination>

      <div className="tc pv3 copy f5" hidden={!hasResults}>
        {pageResultCount} of {searchResults.nbHits} search results
      </div>

      <div className="tc pv4 copy" hidden={hasResults}>
        <NoSearchResults classes="w-100 w-70-l" />
      </div>
    </div>
  ) : (
    <></>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const SearchResultsComponent = connectStateResults(SearchResultsInput)

const SearchPage = ({
  location,
  data: {
    site: { siteMetadata }
  }
}: SearchPageProps) => {
  const appId = process.env.GATSBY_ALGOLIA_APP_ID || ""
  const apiKey = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY || ""
  const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || ""
  return (
    <Layout location={location} siteMetadata={siteMetadata}>
      <WrappedHelmet
        title={`Search | ${siteMetadata.title}`}
        description="Search | LWB - Carer guide"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <InstantSearch appId={appId} apiKey={apiKey} indexName={indexName}>
        <Configure hitsPerPage={10} />
        <div className="bg-lwb-grey-xxx-light tc-ns ph3 ph4-m ph0-l pt5 pb3 pb4-m pb5-l">
          <Container>
            <h1 className="ts-display-1 ma0 mb3 mb4-ns fw8">Search results</h1>
            <div className="w-100 w-70-l center">
              <ConnectedSearchBox
                defaultRefinement={queryFromLocation(location)}
              />
            </div>
          </Container>
        </div>
        <Container>
          <SearchResultsComponent />
        </Container>
      </InstantSearch>
    </Layout>
  )
}

export const query = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default SearchPage
