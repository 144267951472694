interface SearchResults {
  page: number
  hitsPerPage: number
  nbPages: number
  nbHits: number
}

const getPageResultCount = (searchResults?: SearchResults) => {
  if (!searchResults) {
    return "0"
  }
  const { page, hitsPerPage, nbPages, nbHits } = searchResults
  if (!nbHits) {
    return "0"
  }
  const currentPage = page + 1 // first page is 0
  const pageMax = currentPage * hitsPerPage

  const result =
    currentPage === nbPages
      ? `${pageMax - (hitsPerPage - 1)} - ${nbHits}`
      : `${pageMax - (hitsPerPage - 1)} - ${pageMax}`
  return result
}

export default getPageResultCount
