import React from "react"
import styled from "styled-components"
import {
  IconArrowRight,
  Variables
} from "@life-without-barriers/react-components"

const { youthPinkMedium, youthPinkXDark } = Variables

interface Props {
  color?: string
  hover?: string
}

export const StyledReadMore = styled.div`
  color: ${(props: Props) => (props.color ? props.color : youthPinkMedium)};
  transition: color ease 0.3s;
  path {
    fill: ${(props: Props) => (props.color ? props.color : youthPinkMedium)};
    transition: color ease 0.3s;
  }

  .readMoreParentHover:hover &,
  &:hover {
    color: ${(props: Props) => (props.hover ? props.hover : youthPinkXDark)};
    path {
      fill: ${(props: Props) => (props.hover ? props.hover : youthPinkXDark)};
    }
  }
`

const sharedClassNames =
  "z-1 mt2 fw6 flex items-baseline justify-content no-underline"

const ReadMore = (props: Props) => (
  <StyledReadMore {...props} className={sharedClassNames}>
    Read more <IconArrowRight height="8" width="5" className="ml2" />
  </StyledReadMore>
)

export default ReadMore
