import styled from "styled-components"
import { Variables } from "@life-without-barriers/react-components"
const { black, white, youthPinkMedium, greyXXXLight } = Variables

export const StyledPagination = styled.div`
  background-color: ${greyXXXLight};

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  li {
    border-radius: 0.25rem;
    margin: 0 0.75rem;
    padding: 0.3rem 0;
  }

  @media screen and (max-width: 30em) {
    li {
      border-radius: 0.25rem;
      margin: 0 0.5rem;
      padding: 0.3rem 0;
    }
  }

  .ais-Pagination-link {
    padding: 0 0.5rem;
  }

  a {
    text-decoration: none;
    position: relative;
  }

  .ais-Pagination-item--selected {
    background-color: ${youthPinkMedium};
    a {
      color: ${white};
    }
  }

  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--nextPage {
    a,
    a::selection {
      color: transparent;
    }

    a:after {
      display: block;
      height: 10px;
      left: 50%;
      line-height: 0;
      position: absolute;
      top: 50%;
      width: 6px;
    }
  }

  .ais-Pagination-item--disabled span {
    display: none;
  }

  .ais-Pagination-item--previousPage a:after {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='6' viewBox='0 0 34 58'><path d='M9.82 1.583c2.09 2.04 22.51 23.48 22.51 23.48a5.484 5.484 0 0 1 1.67 3.94c0 1.42-.56 2.85-1.67 3.94 0 0-20.42 21.44-22.51 23.47-2.09 2.04-5.85 2.18-8.08 0-2.23-2.17-2.41-5.21 0-7.87l18.74-19.54L1.74 9.463c-2.41-2.67-2.23-5.71 0-7.88 2.23-2.18 5.99-2.04 8.08 0' fill='#d33391'/></svg>");
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .ais-Pagination-item--nextPage a:after {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='6' viewBox='0 0 34 58'><path d='M9.82 1.583c2.09 2.04 22.51 23.48 22.51 23.48a5.484 5.484 0 0 1 1.67 3.94c0 1.42-.56 2.85-1.67 3.94 0 0-20.42 21.44-22.51 23.47-2.09 2.04-5.85 2.18-8.08 0-2.23-2.17-2.41-5.21 0-7.87l18.74-19.54L1.74 9.463c-2.41-2.67-2.23-5.71 0-7.88 2.23-2.18 5.99-2.04 8.08 0' fill='#d33391'/></svg>");
    transform: translate(-50%, -50%);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage {
      a {
        color: ${youthPinkMedium};
        font-size: 24px;
      }

      a::selection {
        color: ${black};
      }
    }
  }
`
